import React from "react"
import { emailToken, formEmailTo } from "../config"
import scrollToElement from "scroll-to-element"
import { Link } from "gatsby"
import ImageUploader from "react-images-upload"
import report from "../images/report.jpeg"
import ModalTrigger from "./modal-trigger"
//import emailjs from 'emailjs-com';
import axios from 'axios';


/*const createBody = (formValues, attachments) => {
  delete formValues.files
  return `
  <td class="esd-stripe" align="center" bgcolor="#f8f9fd" style="background-color: #f8f9fd;">
    <table bgcolor="transparent" class="es-content-body" align="center" cellpadding="0" cellspacing="0" width="600" style="background-color: transparent;">
        <tbody>
            <tr>
                <td class="esd-structure es-p20t es-p10b es-p20r es-p20l" align="left">
                    <table cellpadding="0" cellspacing="0" width="100%">
                        <tbody>
                            <tr>
                                <td width="560" class="esd-container-frame" align="center" valign="top">
                                    <table cellpadding="0" cellspacing="0" width="100%">
                                        <tbody>
                                            <tr>
                                                <td align="center" class="esd-block-text es-p10b">
                                                    <h1>Quote Details</h1>
                                                </td>
                                            </tr>
                                            ${
    attachments.length > 0
      ? `
                                            <tr>
                                                <td align="center" class="esd-block-text es-p10t es-p10b">
                                                    <p><strong>User has submitted ${attachments.length} images. They are attached with this mail.</strong></p>
                                                </td>
                                            </tr>
                                            `
      : ""
    }                                            
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
           ${Object.keys(formValues)
    .map(key => {
      return `
             <tr>
             <td class="esd-structure es-p20t es-p20r es-p20l" align="left">
                 <!--[if mso]><table width="560" cellpadding="0" cellspacing="0"><tr><td width="270" valign="top"><![endif]-->
                 <table cellpadding="0" cellspacing="0" class="es-left" align="left">
                     <tbody>
                         <tr>
                             <td width="270" class="es-m-p20b esd-container-frame" align="left">
                                 <table cellpadding="0" cellspacing="0" width="100%">
                                     <tbody>
                                         <tr>
                                             <td align="center" class="esd-block-text">
                                                 <p style="line-height: 200%;"><strong>${key}</strong></p>
                                             </td>
                                         </tr>
                                     </tbody>
                                 </table>
                             </td>
                         </tr>
                     </tbody>
                 </table>
                 <!--[if mso]></td><td width="20"></td><td width="270" valign="top"><![endif]-->
                 <table cellpadding="0" cellspacing="0" class="es-right" align="right">
                     <tbody>
                         <tr>
                             <td width="270" align="left" class="esd-container-frame">
                                 <table cellpadding="0" cellspacing="0" width="100%">
                                     <tbody>
                                         <tr>
                                             <td align="center" class="esd-block-text">
                                                 <p>${formValues[key]}</p>
                                             </td>
                                         </tr>
                                     </tbody>
                                 </table>
                             </td>
                         </tr>
                     </tbody>
                 </table>
                 <!--[if mso]></td></tr></table><![endif]-->
             </td>
         </tr>
             `
    })
    .join("")}           
        </tbody>
    </table>
</td>
  `
}*/


const Form = () => {
  const [message, setMessage] = React.useState(null)
  const formRef = React.useRef(null)
  const [formValues, setFormValues] = React.useState({})
  const [loading, setLoading] = React.useState(false)

  const onChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files.length > 5) {
        alert("Maximum 5 images are allowed")
        e.target.files = null
        e.target.value = ""
        return
      }
      for (let file of e.target.files) {
        if (file.size > 1024 * 1024 * 15) {
          alert("Maximum File Size Allowed is 15 MB")
          e.target.files = null
          e.target.value = ""
          return
        }
      }
    }
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.files || e.target.value
    })
  }
  const onSubmit = async e => {
    e.preventDefault();
    try{

    //console.log(e.target, "submit log") 
    
    //console.log(JSON.stringify(formValues), 'form values') 
    
  setLoading(true)
    // Make a POST request with the HTML string in the body
    axios.post('https://gatsbynextapi.vercel.app/api/sendEmail', {
      formValues: JSON.stringify(formValues)
    }, {
      headers: {
        'Authorization': 'XFkndax79PyWAQEbwfHdsvvyK92kVWfB72ee'
      }
    })
    .then(response => {
      console.log(response);
      setMessage({
        text: "Your message was sent successfully! We will be in touch shortly!",
          className: "alert-success",
      })
      setLoading(false)

     // e.target.reset();
      formRef.current.reset()
    })
    .catch(error => {
      console.log(error);
      setLoading(false)

      setMessage({
        text: "There was an error while sending your message. Please Try again",
          className: "alert-danger",
      })
    });

   /* emailjs.sendForm('service_iuw9xnu', 'template_koe81qb', e.target, 'user_rKO6Dfg4eWrXeQAYHIa3W')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      if (emailjs.sendForm === "OK") {
        setMessage({          
        text: "There was an error while saving your data. Please Try again",
        className: "alert-danger",
        })
        formRef.current.reset()
      } else {
        throw "Email not sent"
      }*/
    }
    catch (error) {
      console.debug("Error while sending email", { error })
      setMessage({
        text: "There was an error while sending your message. Please Try again",
          className: "alert-danger",
      })
    }
  }
  const [pictures, setPictures] = React.useState([])

  const onDrop = (picture) => {
    setPictures(pictures.concat(picture))
  }

  return (
    <form className="form" onSubmit={onSubmit} ref={formRef}>
      <h3 className="text-primary center">Get Started Today</h3>
      {message && (
        <div className={`alert ${message.className}`} role="alert">
          {message.text}
        </div>
      )}
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-3">
            <label className="form-label">Full Name *</label>
            <input
              id="NAME"
              onChange={onChange}
              name="Name"
              required
              type="text"
              maxLength={100}
              className="form-control"
              placeholder="Name"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label className="form-label">Email address *</label>
            <input
            id="email"
              type="email"
              onChange={onChange}
              name="Email"
              maxLength={100}
              className="form-control"
              placeholder="Email"
              required
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-3">
            <label className="form-label">Phone *</label>
            <input
            id="phone"
              required
              name="Phone"
              onChange={onChange}
              type="phone"
              maxLength={15}
              className="form-control"
              placeholder="Phone"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label className="form-label">Suburb *
              <Link to="/service-area">(See Service Area)</Link>
            </label>
            <input
            id="seesa"
              type="text"
              onChange={onChange}
              name="Suburb"
              className="form-control"
              placeholder="Suburb"
              required
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Details *</label>
        <textarea
        id="details"
          className="form-control"
          onChange={onChange}
          name="Details"
          maxLength={500}
          required
          placeholder="Please include quantities, sizes, weight, site accessibility"
        />
      </div>
      <div className="mb-3">
        {/* <label className="form-label">Upload Images (Optional - Up to 5 Images)</label> */}
        {/*<input
          className="form-control"
          type="file"
          onChange={onChange}
          name="files"
          id="formFileMultiple"
          accept="image/*"
          multiple
        />*/}
        {/* <ModalTrigger
          triggerTitle={"Upload"}
          modalContent={
            <ImageUploader
              withIcon={true}
              buttonText='Choose images'
              onChange={onDrop}
              imgExtension={[".jpg", ".gif", ".png", ".gif"]}
              maxFileSize={5242880}
            />
          }
        /> */}
      </div>
      <div className="action center">
      {loading ? <button type="submit" className="btn btn-green" disabled style={{cursor: 'not-allowed'}}>
          Loading
        </button> : <button type="submit" className="btn btn-green">
          Submit
        </button> } 
      </div>
    </form>
  )
}

export default Form

// const sendMail = (e) => {
//   console.log("function called from inside")
//   var Name = document.getElementById("NAME").value
//   var mail = document.getElementById("email").value
//   var phone = document.getElementById("phone").value
//   var suburab = document.getElementById("seesa").value
//   var Detail = document.getElementById("details").value


//   var Message = Name+mail+phone+suburab+Detail
 
    
// }
